<template>
  <div style="width:100%;">
    <div v-for="item in filesToBeDownloaded" :key="item.taskGroupId" style="border-bottom: 1px solid rgba(0, 0, 0, 0.12);padding: 12px 8px;">
      <v-row style="min-height: 38px;margin-bottom: -16px;">
        <v-col style="text-align: left;padding-top: 10px;">
          <p style="font-size: 14px;">{{ item.name }}</p>
        </v-col>
        <v-col style="text-align: right;padding-top: 10px;">
          <a :href="item.fileUrl" style="text-decoration: none;font-weight: 700;" :style="{'color': this.$vuetify.theme.themes.light.colors.textImgBasedColor}">
            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-bottom:-2px;margin-right:10px;">
              <path d="M12.9062 14.1559H1.09375C0.62793 14.1559 0.25 14.5341 0.25 15.0003C0.25 15.4953 0.62793 15.875 1.09375 15.875H12.9062C13.3721 15.875 13.75 15.4967 13.75 15.0305C13.75 14.5637 13.3738 14.1559 12.9062 14.1559ZM10.8848 6.30195L7.84375 9.53633V0.972266C7.84375 0.503281 7.46758 0.125 7 0.125C6.53242 0.125 6.15625 0.503281 6.15625 0.969453V9.53352L3.11418 6.30195C2.94824 6.12266 2.7243 6.03477 2.5 6.03477C2.29237 6.03477 2.0848 6.11063 1.92203 6.26455C1.58256 6.58338 1.56625 7.11779 1.88579 7.4574L6.38579 12.2422C6.70437 12.583 7.29563 12.583 7.61414 12.2422L12.1141 7.4574C12.4339 7.11768 12.4174 6.58341 12.0779 6.26455C11.7391 5.94336 11.2047 5.9293 10.8848 6.30195Z" :fill="this.$vuetify.theme.themes.light.colors.textImgBasedColor"/>
            </svg> <span class="download">{{ $t('downloadDoc') }}</span></a>
        </v-col>
      </v-row>
    </div>
    <div style="margin-top: 8px;margin-left: 9px;">
      <a v-if="linkToDownloadAllAsZip != ''" :href="linkToDownloadAllAsZip" style="text-decoration: none;font-weight: 700;" :style="{'color': this.$vuetify.theme.themes.light.colors.textImgBasedColor}">
        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-bottom:-2px;margin-right:10px;">
          <path d="M15.75 2.625H9.69961L7.96992 1.03418C7.54805 0.611953 6.97852 0.375 6.38086 0.375H2.25C1.00723 0.375 0 1.38258 0 2.625V13.875C0 15.1174 1.00723 16.125 2.25 16.125H15.75C16.9928 16.125 18 15.1174 18 13.875V4.875C18 3.63398 16.991 2.625 15.75 2.625ZM16.3125 13.875C16.3125 14.1852 16.0601 14.4375 15.75 14.4375H2.25C1.93985 14.4375 1.6875 14.1852 1.6875 13.875V2.625C1.6875 2.31478 1.93985 2.0625 2.25 2.0625H6.38086C6.53108 2.0625 6.67241 2.121 6.77848 2.22731L9 4.3125H15.75C16.0601 4.3125 16.3125 4.56478 16.3125 4.875V13.875ZM11.2148 8.77734L9.84375 10.152V6.5625C9.84375 6.09668 9.46582 5.71875 9 5.71875C8.53418 5.71875 8.15625 6.09844 8.15625 6.5625V10.152L6.75352 8.77734C6.42393 8.44775 5.89008 8.44775 5.56031 8.77734C5.23055 9.10693 5.23072 9.64078 5.56031 9.97055L8.37281 12.783C8.56758 12.9504 8.78555 13.0312 9 13.0312C9.21445 13.0312 9.43172 12.9488 9.5966 12.7841L12.4091 9.97157C12.7387 9.64198 12.7387 9.10813 12.4091 8.77836C12.0795 8.4486 11.5453 8.45039 11.2148 8.77734Z" :fill="this.$vuetify.theme.themes.light.colors.textImgBasedColor"/>
        </svg> {{ $t('downloadAllAsZip') }}</a>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import { ViewDocument } from "@nubeo/ese5-client-ts/src";

@Options({
  components: {  }
})
export default class ListOdDocsToBeDownloaded extends Vue {
  loading = true;
  @Prop({ required: true })
  filesToBeDownloaded!: ViewDocument[];
  @Prop({ required: false, type: String, default: () => "" })
  linkToDownloadAllAsZip: string | undefined;
  mounted(){
    this.loading = true;
    console.log(this.filesToBeDownloaded);
    this.loading = false;
  }
}
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .download {
    display: none;
  }
}
</style>

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import i18n from './i18n';
import Log from "@/assets/ts/Log";

loadFonts();

let app = createApp(App);
app.config.errorHandler = (err, instance, info) => {
    // TODO: Move this logic to central error handling class
    if(err instanceof Error) {
        Log.error(err.name);
        Log.error(err.message);
        if(err.stack)
            Log.error(err.stack);
    } else {
        console.log(err); // TODO: Improve this, to log using central logger
    }

    // TODO: Navigate to error dialog
}
app.use(router)
    .use(vuetify)
    .use(i18n)
    .mount("#app");

import EsignoSEApiAuth from "@/assets/EsignoSEApiAuth";
import {
  AppGoWizardAuthClient,
  AuthUserClient,
  IsCurrentUserAttendeeResponse, UserDto,
  UserNameInSessionResponse
} from "@nubeo/ese5-client-ts";
import ErrorFactory from "@/assets/ts/ErrorFactory";

const logger = require('pino')();

export default class EsignoSEApiAuthUserClient {
  errorFactory: ErrorFactory = new ErrorFactory();
  async getUserInformation(userId: string, accessToken: string): Promise<UserDto>{
    var importDefs = new EsignoSEApiAuth(accessToken);
    var userClient = new AuthUserClient(await importDefs.getEseBaseUrl(), importDefs.fetchHandler);
    var result = new UserDto();
    await userClient.getUser(userId).then(data => {
      result = data;
    }).catch(exx => async function () {
      var result = await importDefs.refreshSession(sessionStorage.getItem("refreshToken"));
      if(result?.accessToken != null){
        sessionStorage.setItem("accessToken", result?.accessToken);
        var importDefsNew = new EsignoSEApiAuth(result?.accessToken);
        var userClientNew = new AuthUserClient(await importDefsNew.getEseBaseUrl(), importDefsNew.fetchHandler);
        var resultResp = await userClientNew.getUser(userId).then(data => {
          return data;
        }).catch(ex => {
          var e = JSON.parse(ex.response);
          sessionStorage.setItem("infoText", "Ajaj, někde nastala chyba...");
          sessionStorage.setItem("infoDescription", e.Errors[0].ErrorMessage);
          sessionStorage.setItem("showDetailUrl", "");
          window.location.href = "/info-dialog/errorSignImage";
          return new UserDto();
        });
        return resultResp;
      }else{
        return new UserDto();
      }
    });
    return result;
  }

  async getCurrentUserName(sessionId: string, token: string): Promise<UserNameInSessionResponse>{
    var esigno = new EsignoSEApiAuth(token);
    var appGoWizardAuthClient = new AppGoWizardAuthClient(await esigno.getEseBaseUrl(), esigno.fetchHandler);
    var resultResp = await appGoWizardAuthClient.getUserNameInSession(sessionId).then(data => {
      return data;
    }).catch(ex => {
      this.errorFactory.DetectErrorResponse(ex);
      var e = JSON.parse(ex.response);
      this.errorFactory.returnError(e.Errors[0].ErrorMessage, e.Errors[0].ErrorMessage);
      return new UserNameInSessionResponse();
    });
    return resultResp;
  }

  async isAttendeeInSession(sessionId: string, attendeeId: string, token: string): Promise<IsCurrentUserAttendeeResponse>{
    var esigno = new EsignoSEApiAuth(token);
    var appGoWizardAuthClient = new AppGoWizardAuthClient(await esigno.getEseBaseUrl(), esigno.fetchHandler);
    var resultResp = await appGoWizardAuthClient.isAttendee(sessionId, attendeeId).then(data => {
      return data;
    }).catch(ex => {
      this.errorFactory.DetectErrorResponse(ex);
      var e = JSON.parse(ex.response);
      this.errorFactory.returnError(e.Errors[0].ErrorMessage, e.Errors[0].ErrorMessage);
      return new IsCurrentUserAttendeeResponse();
    });
    return resultResp;
  }
}

<template>
  <div class="mainDivLoading" style="background: #FFFFFF;box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);border-radius: 8px;padding: 48px;max-width:900px;text-align: left;position: sticky;z-index: 2;margin-bottom: 80px;text-align: center;">
    <v-progress-circular
      :size="90"
      :width="8"
      :color="this.$vuetify.theme.themes.light.colors.globalBackground"
      indeterminate
    ></v-progress-circular>
  </div>
  <p class="footerText" v-if="this.$vuetify.theme.themes.light.colors.showFooter == '1'" >{{ $t("footerInfo") }} </p>
</template>

<script lang="ts">
import Navbar from "@/components/navbar.vue";
import { Options, Vue } from "vue-property-decorator";

@Options({
  components: { Navbar }
})
export default class LoadingView extends Vue {
  loading = true;

  mounted(){
    this.loading = true;
    this.loading = false;
  }
}
</script>
<style>
@media only screen and (max-width: 900px) {
    .mainDivLoading {
        margin-left: 10px !important;
        width: 97.4vw !important;
        padding: 25px !important;
        margin-bottom: 30px !important;
    }
}
@media only screen and (max-width: 660px) {
  .mainDivLoading {
    margin-left: 10px !important;
    width: 97vw !important;
    padding: 25px !important;
    margin-bottom: 30px !important;
  }
}
@media only screen and (max-width: 704px) {
    .mainDivLoading {
        margin-left: 10px !important;
        width: 97.4vw !important;
        padding: 25px !important;
        margin-bottom: 30px !important;
    }
}
@media only screen and (max-width: 704px) {
  .mainDivLoading {
    margin-left: 10px !important;
    width: 96vw !important;
    padding: 25px !important;
    margin-bottom: 30px !important;
  }
}
@media only screen and (max-width: 450px) {
  .mainDivLoading {
    margin-left: 10px !important;
    width: 95vw !important;
    padding: 25px !important;
    margin-bottom: 30px !important;
  }
}
</style>

<template>
  <v-app>
    <router-view/>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {UserActivityTracking} from "./assets/UserActivityTracking";
import EsignoSEApiAuth from "@/assets/EsignoSEApiAuth";
import router from "./router";

export default defineComponent({
  name: "App",
  async mounted() {
    await UserActivityTracking();
    let accessToken = sessionStorage.getItem("accessToken") ?? "";
    let esignoSEApiAuth = new EsignoSEApiAuth(accessToken);
    let canBeOpen = await esignoSEApiAuth.isSessionOpenable();
    if(!canBeOpen && !window.location.pathname.includes("info-dialog") && !window.location.pathname.includes("open")){
      sessionStorage.setItem("infoText", this.$t('dialogs.error.expire.title'));
      sessionStorage.setItem("infoDescription", this.$t('dialogs.error.expire.description'));
      sessionStorage.setItem("showDetailUrl", "");
      router.push("/info-dialog/errorSignImage");
    }
  },
  data() {
    return {
      //
    };
  },
});
</script>
<style>
.v-application__wrap{
  overflow: visible !important;
  overflow-x: hidden !important;
  height: 100vh;
}
.footerText{
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.17px;
  color: #20252B99;
  bottom: 32px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mainDiv{
  z-index: 99;
}
.v-container{
  /* Set z-index to high level to be before every element correctly */
  z-index: 99 !important;
}
@media screen and (max-width: 600px) {
  .footerText {
    display: none;
    margin-bottom: 2em !important;
  }
}

@media screen and (max-height: 800px) {
  .footerText {
    display: none;
  }
}
</style>

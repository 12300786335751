<template>
  <div>
    <v-dialog
      v-model="dialogDownload"
      width="800"
      style="margin: 10px;"
    >
      <v-card>
        <v-card-title style="padding: 30px;">
          <p>{{ $t("docsToBeDownloaded") }}</p>
          <p style="font-size: 14px;margin-top:8px;margin-bottom:-37px;">{{ $t("envelopeContains") }}</p>
          <img @click="dialogDownload = false" alt="close" src="/img/closeBtn.svg" style="position: absolute;right: 21px;top: 21px;cursor:pointer;" />
        </v-card-title>
        <v-card-text>
          <list-od-docs-to-be-downloaded :filesToBeDownloaded="filesToBeDownloaded" :linkToDownloadAllAsZip="linkToDownloadAllAsZip"></list-od-docs-to-be-downloaded>
        </v-card-text>
        <v-card-actions>

        </v-card-actions>
      </v-card>
    </v-dialog>
  <v-card class="overflow-hidden" style="box-shadow: none !important; border-radius: 0;margin-bottom: 0;">
    <v-app-bar
      absolute
      :style="{ 'background-color': this.$vuetify.theme.themes.light.colors.bcColor, 'color': this.$vuetify.theme.themes.light.colors.textColor }"
      style="border-radius: 0;"
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
    >
      <div class="nav-bar-container">
        <div class="nav-bar-logo">
          <img
            :src="prefix + mainIcon"
            alt=""
          >
        </div>

        <v-toolbar-title class="mdHide" style="text-align: center;font-size: 16px;min-width: 132px;" v-if="this.$vuetify.theme.themes.light.colors.showCenterText == '1'">{{ $t("electronicSignature") }}</v-toolbar-title>

        <div
          style="display: contents;"
          v-if="showPartInNavbarWithUserInfo && !loading"
        >
          <select v-if="this.$vuetify.theme.themes.light.colors.showLanguageChoose == '1'" class="mobileHide" v-model="$i18n.locale" id="languageSelect" style="margin-right: 1em;">
            <option value="cs">cs-CZ</option>
            <option value="en">en-US</option>
          </select>
          <img @click="dialogDownload = true" v-if="this.$vuetify.theme.themes.light.colors.showDocumentInfo == '1' && downloadDocVar && showPartInNavbarWithUserInfoDownloadDialog && showPartInNavbarWithUserInfoDownloadDialogOwn" src="../img/docInfo.svg" style="cursor: pointer;margin-right: 32px;" alt="docInfo"/>

          <div class="avatar-and-client-name">
            <img class="descriptionOfClientImg" :src="this.$vuetify.theme.themes.light.colors.avatar" alt="userAvatarDef"/>

            <div class="mobileHide" style="margin-left: 8px;margin-right: 16px;">
              <p style="font-weight: 400; font-size: 14px;max-width: 102px;max-height: 32px;">{{ nameOfClient }}</p>
              <p v-if="this.$vuetify.theme.themes.light.colors.showDescOfClient == '1'" style="font-weight: 400; float: left;font-size: 12px; color: rgba(255, 255, 255, 0.6);max-width: 102px;max-height: 32px;">{{ descOfClient }}</p>
            </div>
          </div>
        </div>
        <div
          v-else
          class="nav-bar-empty-container"
        />
      </div>


    </v-app-bar>
    <v-sheet
      id="scrolling-techniques-7"
      class="overflow-y-auto"
      max-height="600"
    >
      <!--To be deleted JK-->
      <v-container style="height: 64px;">
      </v-container>
    </v-sheet>
  </v-card>
    <div v-if="showUserDetail" style="background: url('/img/dropdownTooltip.svg'); position: fixed;width: 16.5em;height: 7.6em;right: 6px;top: 56px;z-index: 3;" v-click-outside="clickOutside">
      <div style="padding: 16px;margin-bottom: 0;">
        <v-row style="margin-top: 1px;margin-bottom: 1px;">
          <v-col cols="3">
            <img class="descriptionOfClientImg" :src="this.$vuetify.theme.themes.light.colors.avatar" style="cursor: pointer;" alt="userAvatarDef"/>
          </v-col>
          <v-col style="text-align: left;">
            <p style="font-weight: 600; font-size: 12px;max-width: 102px;max-height: 32px;margin-top:10px;">{{ nameOfClient }}</p>
          </v-col>
        </v-row>
        <hr style="border: 1px solid #F2F2F2;" />
      </div>
      <div style="padding: 16px;margin-top: -22px;padding-top: 0;">
        <v-row>
          <v-col cols="6">
            <p style="cursor: pointer;font-size: 12px;font-weight: 600;color: #888888;"><img id="logoutImg" src="/img/logout.svg" alt="logout" style="margin-bottom: -1px;" /> Odhlásit se</p>
          </v-col>
          <v-col style="text-align: right;">
            <p style="cursor: pointer;font-weight: 400; font-size: 12px;text-decoration: underline;" :style="{'color':this.$vuetify.theme.themes.light.colors.menuAboutApp}">O aplikaci</p>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import ListOdDocsToBeDownloaded from "@/components/list-od-docs-to-be-downloaded.vue";
import EsignoSEApiGoWizardSign from "@/assets/EsignoSEApiGoWizardSign";
import { ViewDocument } from "@nubeo/ese5-client-ts/src";
import { AppGoWizardClient } from "@nubeo/ese5-client-ts";
import EsignoSEApiAppGoWizard from "@/assets/EsignoSEApiAppGoWizard";
import Loading from "@/components/new/loading.vue";

@Options({
  components: { Loading, ListOdDocsToBeDownloaded }
})
export default class Navbar extends Vue {

  @Prop({ required: false, type: String, default: () => "" })
  readonly nameOfClient!: string;

  @Prop({ required: false, type: String, default: () => "" })
  readonly descOfClient!: string;

  @Prop({ required: false, type: Boolean, default: () => true })
  readonly showPartInNavbarWithUserInfo!: boolean;

  @Prop({ required: false, type: Boolean, default: () => true })
  showPartInNavbarWithUserInfoDownloadDialog!: boolean;

  showPartInNavbarWithUserInfoDownloadDialogOwn = true;

  dialogDownload=false;
  linkToDownloadAllAsZip: string | undefined;
  filesToBeDownloaded: ViewDocument[];
  filesToBeUploaded: any = [];
  showUserDetail = false;
  downloadDocVar = true;
  loading = true;
  eseSign: EsignoSEApiGoWizardSign;
  eseGoClient: EsignoSEApiAppGoWizard;
  prefix: string = "";
  mainIcon: string = "";

  async mounted(){
    this.loading = true;
    this.eseGoClient = new EsignoSEApiAppGoWizard();
    this.filesToBeUploaded[0] = [];
    this.downloadDocVar = false;
    await this.getDefaultTheme();
    if(this.$route.params.attendeeId != undefined){
      this.prefix = "../../";
    }else if(this.$route.params.sessionId != undefined){
      this.prefix = "../";
    }
    this.loading = false;
    if(this.showPartInNavbarWithUserInfoDownloadDialog && this.showPartInNavbarWithUserInfo){
      this.eseSign = new EsignoSEApiGoWizardSign();
      var accessToken = sessionStorage.getItem("accessToken");
      var sessionId = sessionStorage.getItem("sessionId");
      var attendeeId = sessionStorage.getItem("attendeeId");
      if(sessionId != null && accessToken != null && attendeeId != null){
        var docs = await this.eseSign.getAllViewDoc(accessToken, sessionId, attendeeId);
        if(docs.attachedDocuments != null && docs.attachedDocuments.length > 0){
          this.linkToDownloadAllAsZip = docs.allDocumentsZippedUrl;
          this.filesToBeDownloaded = docs.attachedDocuments;
          this.downloadDocVar = true;
        }else
          this.showPartInNavbarWithUserInfoDownloadDialogOwn = false;
      }else
        this.showPartInNavbarWithUserInfoDownloadDialogOwn = false;
    }
  }

  clickOutside(){
    this.showUserDetail = false;
  }

  async getDefaultTheme(){
    var sessionId = sessionStorage.getItem("sessionId");
    if(sessionId != null){
      let result = await this.eseGoClient.getClientConfiguration(sessionId);
      if(result.templateName != null){
        this.mainIcon = this.$vuetify.theme.current.variables[result.templateName].toString();
      }
    }
    if(this.mainIcon == null || this.mainIcon == "")
      this.mainIcon = this.$vuetify.theme.current.variables.GCP.toString();
  }
}
</script>

<style>
.v-toolbar__content, .v-toolbar__extension{
  width: 100% !important;
}

.avatar-and-client-name{
  display: flex;
  flex-direction: row;
  gap: 8px;

  justify-content: flex-end;
  align-items: center;

  min-width: 273px;
}

.descriptionOfClientImg{
  width: 3em;
}

.nav-bar-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.nav-bar-logo{
  min-width: 273px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-bar-empty-container{
  min-width: 273px;
}

@media only screen and (max-width: 500px) {
  .mobileHide {
    display: none;
  }
  .descriptionOfClientImg {
    margin-right: 1em !important;
  }

  .avatar-and-client-name{
    min-width: 0;
  }

  .nav-bar-empty-container{
    min-width: 0;
  }
}

@media only screen and (max-width: 700px) {
  .mdHide {
    display: none;
  }
}

</style>

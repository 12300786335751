import Log from "@/assets/ts/Log";
import {ErrorResponse} from "@nubeo/ese5-client-ts";

export default class ErrorFactory {

    private sessionId: string|undefined;
    private attendeeId: string|undefined;
    private canNotFound: string = "can not found";

    constructor(sessionId?: string, attendeeId?: string) {
        this.sessionId = sessionId;
        this.attendeeId = attendeeId;
    }

    public CreateErrorLogMessage(error: unknown) : string {
        if(error instanceof ErrorResponse) {
            return this.CreateErrorLogMessageFromApiResponse(error);
        } else if(error instanceof Error) {
            return this.CreateErrorLogMessageFromError(error);
        } else {
            return "Unknown error occured";
        }
    }

    public CreateErrorDialogProps(error: unknown) : unknown {
        if(error instanceof ErrorResponse) {
            return this.CreateErrorDialogPropsFromApiResponse(error);
        } else if(error instanceof Error) {
            return this.CreateErrorDialogPropsFromError(error);
        } else {
            return this.CreateErrorDialogDefaultProps();
        }
    }

    private CreateErrorLogMessageFromError(error: Error) : string {
        return error.message;
    }

    private CreateErrorLogMessageFromApiResponse(error: ErrorResponse) : string {
        let message = "";
        if(error.errorCode)
            message += `${error.errorCode}\n`;

        if(error.errors != undefined) {
            for(let err of error.errors) {
                message += `${err.errorMessage}\n`
            }
        }

        return message;
    }

    private CreateErrorDialogPropsFromError(error: Error) : unknown {
        return {
            sessionId: this.sessionId,
            attendeeId: this.attendeeId,
            errorMessage: error.message,
            logs: Log.getLastMessages(),
        }
    }

    private CreateErrorDialogPropsFromApiResponse(error: ErrorResponse) : unknown {
        let message = this.CreateErrorLogMessageFromApiResponse(error);

        return {
            sessionId: this.sessionId,
            attendeeId: this.attendeeId,
            errorMessage: message,
            logs: Log.getLastMessages(),
        }
    }

    private CreateErrorDialogDefaultProps() : unknown {
        return {
            sessionId: this.sessionId,
            attendeeId: this.attendeeId,
        }
    }

    public DetectErrorResponse(ex: any){
        if(ex instanceof ErrorResponse) {
            if(ex.errorCode?.includes("ESE_AUTH")){
                let sessionId = sessionStorage.getItem("sessionId");
                let attendeeId = sessionStorage.getItem("attendeeId");
                sessionStorage.removeItem("accessToken");
                sessionStorage.removeItem("refreshToken");
                window.location.href = "/open/"+sessionId+"/"+attendeeId;
            }
            if(ex.errors != null)
                this.returnError(ex.errorCode, ex.errors[0].errorMessage);
            this.returnError(ex.errorCode, ex.errorCode);
        }
    }

    public returnError(e:any, errorMessage: string | undefined){
        if(errorMessage?.toLowerCase().includes(this.canNotFound)){
            sessionStorage.removeItem("attendeeId");
            sessionStorage.removeItem("sessionId");
        }
        sessionStorage.setItem("infoText", "");
        sessionStorage.setItem("infoDescription", e);
        sessionStorage.setItem("showDetailUrl", "");
        window.location.href = "/info-dialog/errorSignImage";
    }

    /**
     * Redirects to error page when exception is passed to it
     * @param ex Exception
     */
    public returnErrorFromException(ex: any) {
        let errorCode : string;
        let message : string | undefined;

        if(ex instanceof ErrorResponse) {
            errorCode = ex.errorCode ?? "";
            message = ex.errors ? ex.errors[0].errorMessage : errorCode;
        } else {
            // This code is not really ideal either :(
            let e = JSON.parse(ex.response);
            errorCode = e.Errors[0].ErrorMessage;
            message = e.Errors[0].ErrorMessage;
        }

        this.returnError(errorCode, message);
    }

}

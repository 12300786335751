<template>
  <div v-if="!loading">
    <navbar
      :showPartInNavbarWithUserInfo="false"
    ></navbar>
    <img :src="this.$vuetify.theme.themes.light.colors.bcImage" style="width:102vw;height: 450px;object-fit: cover" alt="bcImage"/>
    <div class="mainDiv" style="background: #FFFFFF;box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);border-radius: 8px;padding: 48px;margin: auto;margin-top:-26em;max-width:900px;text-align: left;position: sticky;z-index: 2;margin-bottom: 80px;text-align: center;">
      <img src="/img/404.svg" alt="404"/>
      <h2>{{ $t("pageNotFound") }}</h2>
      <p style="color: #20252B;font-size: 16px;font-weight: 400;margin-top: 10px;">{{ $t("pleaseInsertCorrectFormatOfURL") }}</p>
    </div>
    <p class="footerText" v-if="this.$vuetify.theme.themes.light.colors.showFooter == '1'" >{{ $t("footerInfo") }} </p>
  </div>
  <div v-else>
    <loading-view></loading-view>
  </div>
</template>

<script lang="ts">
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import LoadingView from "@/components/loading-view.vue";
import Navbar from "@/components/navbar.vue";

@Options({
  components: { Navbar, LoadingView }
})
export default class NotFoundView extends Vue {
  loading = true;

  mounted(){
    this.loading = true;
    this.loading = false;
  }
}
</script>

<style scoped>

</style>

import {Logger} from "pino";

export default class Log {

    private static readonly HISTORY_SIZE = 10;

    private static log : Logger = require('pino')();

    private static history : string[] = [];

    public static debug(message: string) {
        this.log.debug(message);
        this.storeMessageInHistory(`[DBG] ${message}`);
    }

    public static info(message: string) {
        this.log.info(message);
        this.storeMessageInHistory(`[INF] ${message}`);
    }

    public static warn(message: string) {
        this.log.warn(message);
        this.storeMessageInHistory(`[WRN] ${message}`);
    }

    public static error(message: string) {
        this.log.error(message);
        this.storeMessageInHistory(`[ERR] ${message}`);
    }

    public static getLastMessages() : string {
        return this.history.join("\n");
    }

    private static storeMessageInHistory(message: string) {
        while(this.history.length >= this.HISTORY_SIZE)
            this.history.pop();

        this.history.unshift(message);
    }
}
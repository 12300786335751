import EsignoSEApiAuth from "@/assets/EsignoSEApiAuth";
import {
  AllTasksFinishedResponse,
  AppGoWizardSignClient,
  ConfirmSignDocumentsViewedResponse,
  ErrorResponse,
  GetAllSignedDocumentsResponse,
  GetAvailableSignMethodsResponse,
  GetSignedDocumentsResponse,
  GetViewDocumentsResponse
} from "@nubeo/ese5-client-ts";
import ErrorFactory from "@/assets/ts/ErrorFactory";

const logger = require('pino')();

export default class EsignoSEApiGoWizardSign {
  errorFactory: ErrorFactory = new ErrorFactory();
  
  async getAllSignDoc(token: string, sessionId: string, attendeeId: string): Promise<GetSignedDocumentsResponse>{
    var esigno = new EsignoSEApiAuth(token);
    var sessionConfigurationClient = new AppGoWizardSignClient(await esigno.getEseBaseUrl(), esigno.fetchHandler);
    var resultResp = await sessionConfigurationClient.getSignDocuments(sessionId, attendeeId).then(data => {
      return data;
    }).catch(ex => {
      if(ex instanceof ErrorResponse) {
        if(ex.errors != null)
          this.errorFactory.returnError(ex.errorCode, ex.errors[0].errorMessage);
        this.errorFactory.returnError(ex.errorCode, ex.errorCode);
      }
      var e = JSON.parse(ex.response);
      this.errorFactory.returnError(e.Errors[0].ErrorMessage, e.Errors[0].ErrorMessage);
      return new GetSignedDocumentsResponse();
    });
    return resultResp;
  }

  async getAllNonSupervisorSignDocuments(token: string, sessionId: string): Promise<GetAllSignedDocumentsResponse>{
    var esigno = new EsignoSEApiAuth(token);
    var sessionConfigurationClient = new AppGoWizardSignClient(await esigno.getEseBaseUrl(), esigno.fetchHandler);
    var resultResp = await sessionConfigurationClient.getNonSupervisorSignDocuments(sessionId).then(data => {
      return data;
    }).catch(ex => {
      if(ex instanceof ErrorResponse) {
        if(ex.errors != null)
          this.errorFactory.returnError(ex.errorCode, ex.errors[0].errorMessage);
        this.errorFactory.returnError(ex.errorCode, ex.errorCode);
      }
      var e = JSON.parse(ex.response);
      this.errorFactory.returnError(e.Errors[0].ErrorMessage, e.Errors[0].ErrorMessage);
      return new GetAllSignedDocumentsResponse();
    });
    return resultResp;
  }

  async getAllViewDoc(token: string, sessionId: string, attendeeId: string): Promise<GetViewDocumentsResponse>{
    var esigno = new EsignoSEApiAuth(token);
    var sessionConfigurationClient = new AppGoWizardSignClient(await esigno.getEseBaseUrl(), esigno.fetchHandler);
    var resultResp = await sessionConfigurationClient.getAttachedDocuments(sessionId, attendeeId).then(data => {
      return data;
    }).catch(ex => {
      if(ex instanceof ErrorResponse) {
        if(ex.errors != null)
          this.errorFactory.returnError(ex.errorCode, ex.errors[0].errorMessage);
        this.errorFactory.returnError(ex.errorCode, ex.errorCode);
      }
      var e = JSON.parse(ex.response);
      this.errorFactory.returnError(e.Errors[0].ErrorMessage, e.Errors[0].ErrorMessage);
      return new GetViewDocumentsResponse();
    });
    return resultResp;
  }

  async confirm(token: string, sessionId: string, attendeeId: string): Promise<ConfirmSignDocumentsViewedResponse>{
    var esigno = new EsignoSEApiAuth(token);
    var sessionConfigurationClient = new AppGoWizardSignClient(await esigno.getEseBaseUrl(), esigno.fetchHandler);
    var resultResp = await sessionConfigurationClient.confirmSignDocumentsViewed(sessionId, attendeeId).then(data => {
      return data;
    }).catch(ex => {
      if(ex instanceof ErrorResponse) {
        if(ex.errors != null)
          this.errorFactory.returnError(ex.errorCode, ex.errors[0].errorMessage);
        this.errorFactory.returnError(ex.errorCode, ex.errorCode);
      }
      var e = JSON.parse(ex.response);
      this.errorFactory.returnError(e.Errors[0].ErrorMessage, e.Errors[0].ErrorMessage);
      return new ConfirmSignDocumentsViewedResponse();
    });
    return resultResp;
  }

  async methods(token: string, sessionId: string, attendeeId: string): Promise<GetAvailableSignMethodsResponse>{
    var esigno = new EsignoSEApiAuth(token);
    var sessionConfigurationClient = new AppGoWizardSignClient(await esigno.getEseBaseUrl(), esigno.fetchHandler);
    var resultResp = await sessionConfigurationClient.getSignMethods(sessionId, attendeeId).then(data => {
      return data;
    }).catch(ex => {
      if(ex instanceof ErrorResponse) {
        if(ex.errors != null)
          this.errorFactory.returnError(ex.errorCode, ex.errors[0].errorMessage);
        this.errorFactory.returnError(ex.errorCode, ex.errorCode);
      }
      var e = JSON.parse(ex.response);
      this.errorFactory.returnError(e.Errors[0].ErrorMessage, e.Errors[0].ErrorMessage);
      return new GetAvailableSignMethodsResponse();
    });
    return resultResp;
  }

  async finished(token: string, sessionId: string, attendeeId: string): Promise<AllTasksFinishedResponse>{
    var esigno = new EsignoSEApiAuth(token);
    var sessionConfigurationClient = new AppGoWizardSignClient(await esigno.getEseBaseUrl(), esigno.fetchHandler);
    var resultResp = await sessionConfigurationClient.areAllSignTasksFinished(sessionId, attendeeId).then(data => {
      return data;
    }).catch(ex => {
      if(ex instanceof ErrorResponse) {
        if(ex.errors != null)
          this.errorFactory.returnError(ex.errorCode, ex.errors[0].errorMessage);
        this.errorFactory.returnError(ex.errorCode, ex.errorCode);
      }
      var e = JSON.parse(ex.response);
      this.errorFactory.returnError(e.Errors[0].ErrorMessage, e.Errors[0].ErrorMessage);
      return new AllTasksFinishedResponse();
    });
    return resultResp;
  }
}

<template>
  <div
      :class="showBackground ? 'loading-container' : ''"
      class="loading-resolution"
  >
    <v-progress-circular
        color="main"
        indeterminate
        :size="64"
        :width="6" />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "Loading",
  props: {
    showBackground: {
      type: Boolean,
      default: true
    }
  }
})
</script>

<style scoped>
  .loading-resolution{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 623px;
    min-width:400px;
    height: 376px;
    min-height: 376px;
  }

  .loading-container{
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    position: sticky;
    z-index: 10;
  }

  @media only screen and (max-width: 900px) {
    .loading-resolution {
      min-width: 45% !important;
    }
  }
</style>
import {AuthLoginAttendeeSessionClient, GetWizardRouterResponse} from "@nubeo/ese5-client-ts";
import EsignoSEApiAuth from "@/assets/EsignoSEApiAuth";
import ErrorFactory from "@/assets/ts/ErrorFactory";
import {OpenAttendeeLoginSessionCommand, OpenAttendeeLoginSessionResponse} from "@nubeo/ese5-client-ts/src";

const logger = require('pino')();

export default class EsignoSEApiAuthLoginSession {
  esignoSEApi: EsignoSEApiAuth;
  errorFactory: ErrorFactory = new ErrorFactory();

  constructor() {
    this.esignoSEApi = new EsignoSEApiAuth("");
  }

  public async openSession(authCode: string | undefined): Promise<OpenAttendeeLoginSessionResponse>{
    var userSessionClient = new AuthLoginAttendeeSessionClient(await this.esignoSEApi.getEseBaseUrl());

    const command = new OpenAttendeeLoginSessionCommand();

    command.authCode = authCode;

    var resultResp = await userSessionClient.openUserSession(command).then(data => {
      if(data.refreshToken != null)
        sessionStorage.setItem("refreshToken", data.refreshToken);
      return data;
    }).catch(ex => {
      this.errorFactory.DetectErrorResponse(ex);
      var e = JSON.parse(ex.response);
      this.errorFactory.returnError(e.Errors[0].ErrorMessage, e.Errors[0].ErrorMessage);
      return new GetWizardRouterResponse();
    });
    return resultResp;
  }

  public async closeSession(accessToken: string): Promise<void>{
    var esigno = new EsignoSEApiAuth(accessToken);
    var appGoWizardFinishActionClient = new AuthLoginAttendeeSessionClient(await esigno.getEseBaseUrl(), esigno.fetchHandler);
    var resultResp = await appGoWizardFinishActionClient.closeUserSession().then(data => {
      return data;
    }).catch(ex => {
      this.errorFactory.DetectErrorResponse(ex);
      var e = JSON.parse(ex.response);
      this.errorFactory.returnError(e.Errors[0].ErrorMessage, e.Errors[0].ErrorMessage);
      return;
    });
    return resultResp;
  }
}

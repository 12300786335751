import AppLayout from "@/components/layout/appLayout.vue";
import AppLayoutNoUser from "@/components/layout/appLayoutNoUser.vue";
import NotFoundView from "@/views/errors/not-found-view404.vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

console.warn = function () {};
const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    component: NotFoundView
  },
  {
    path: "/pdf-app",
    name: "PDF",
    component: () =>
      import(/* webpackChunkName: "document" */ "../views/document-view.vue"),
  },
  {
    path: "/layout",
    name: "Layout",
    component: AppLayout,
    children: [
      {
        path: "/external-sign",
        name: "External Certificate",
        component: () =>
          import(/* webpackChunkName: "externalSign" */ "../views/download-upload-docs-view.vue"),
      },
      {
        path: "/upload",
        name: "UPLOAD",
        component: () =>
          import(/* webpackChunkName: "uploadAttachment" */ "../views/upload-attachment-view.vue"),
      },
      {
        path: "/bankid-result",
        name: "BankId result",
        component: () =>
          import(/* webpackChunkName: "bankIdResult" */ "../views/bankid-result.vue"),
      },
      {
        path: "/redirect",
        name: "Redirect",
        component: () =>
          import(/* webpackChunkName: "redirect" */ "../views/redirect-view.vue"),
      },
      {
        path: "/info",
        name: "Info",
        component: () =>
          import(/* webpackChunkName: "info" */ "../views/info-view.vue"),
      },
      {
        path: "/supervisor-launcher",
        name: "Sign by one click",
        component: () =>
          import(/* webpackChunkName: "supervisorLauncher" */ "../views/new/supervisor-launcher.vue"),
      },
      {
        path: "/choose-sign",
        name: "Choose sign method",
        component: () =>
          import(/* webpackChunkName: "chooseSign" */ "../views/choose-sign-method-view.vue"),
      },
      {
        path: "/qr-code",
        name: "QR code view",
        component: () =>
          import(/* webpackChunkName: "qrCode" */ "../views/qr-code-view.vue"),
      },
      {
        path: "/sms-sign",
        name: "SMS sign",
        component: () =>
          import(/* webpackChunkName: "smsSign" */ "../views/sms-sign-view.vue"),
      },
      {
        path: "/open/:sessionId/:attendeeId",
        alias: ["/open/:sessionId", "/open"],
        name: "Open session",
        component: () =>
            import(/* webpackChunkName: "open" */ "../views/open-view.vue"),
      },
      {
        path: "/wizard/:sessionId/:attendeeId",
        alias: [ "/wizard/:sessionId", "/wizard" ],
        name: "Wizard",
        component: () =>
            import(/* webpackChunkName: "clientLauncher" */ "@/views/new/main.vue")
      },
      {
        path: "/sms-login",
        name: "SMS login",
        component: () =>
            import(/* webpackChunkName: "smsEntry" */ "../views/sms-entry-view.vue"),
      },
      {
        path: "/info-dialog/:id",
        name: "Success/Error states",
        component: () =>
            import(/* webpackChunkName: "successError" */ "../views/success-error-view.vue"),
      },
      {
        path: "/meta/version",
        name: "Version information",
        component: () =>
            import(/* webpackChunkName: "metaVersion" */ "../views/meta-version.vue")
      }
    ]
  },
  {
    path: "/go-reader",
    name: "GO reader",
    component: () =>
      import(/* webpackChunkName: "goReader" */ "../views/go-reader.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

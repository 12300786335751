import {
  AppGoWizardAuthClient,
  AppGoWizardClient,
  AppGoWizardSupervisorLauncherClient,
  ErrorResponse,
  GetAllSessionDocumentsResponse,
  GetAttendeeInformationResponse,
  GetClientSignMethodsResponse,
  GetGoClientConfigurationResponse,
  GetLauncherLocalStateResponse,
  GetLauncherStateResponse,
  GetMethodsResponse,
  GetSessionAttendeesInformationResponse,
  GetSupervisorInformationResponse,
  GetWizardRouterResponse,
  SendToOtherAttendeesResponse,
  SignAllResponse, ValidationErrorResponse
} from "@nubeo/ese5-client-ts";
import EsignoSEApiAuth from "@/assets/EsignoSEApiAuth";
import ErrorFactory from "@/assets/ts/ErrorFactory";
import {SignAllDocumentsApiCommand} from "@nubeo/ese5-client-ts/src";

const logger = require('pino')();
export default class EsignoSEApiAppGoWizard {
  esignoSEApi: EsignoSEApiAuth;
  errorFactory: ErrorFactory = new ErrorFactory();

  constructor() {
    let accessToken = sessionStorage.getItem("accessToken") ?? ""; // Maybe we should throw an exception here?
    this.esignoSEApi = new EsignoSEApiAuth(accessToken);
  }

  public async route(sessionId: string, attendeeId: string): Promise<GetWizardRouterResponse>{
    var appGoWizardClient = new AppGoWizardClient(await this.esignoSEApi.getEseBaseUrl());
    var resultResp = await appGoWizardClient.getRoute(sessionId, attendeeId).then(data => {
      return data;
    }).catch(ex => {
      if(ex instanceof ErrorResponse) {
        if(ex.errors != null)
          this.errorFactory.returnError(ex.errorCode, ex.errors[0].errorMessage);
        this.errorFactory.returnError(ex.errorCode, ex.errorCode);
      }
      var e = JSON.parse(ex.response);
      this.errorFactory.returnError(e.Errors[0].ErrorMessage, e.Errors[0].ErrorMessage);
      return new GetWizardRouterResponse();
    });
    return resultResp;
  }

  public async routeAnonymous(sessionId: string): Promise<GetWizardRouterResponse>{
    var appGoWizardClient = new AppGoWizardClient(await this.esignoSEApi.getEseBaseUrl());
    var resultResp = await appGoWizardClient.getAnonymousRoute(sessionId).then(data => {
      return data;
    }).catch(ex => {
      if(ex instanceof ErrorResponse) {
        if(ex.errors != null)
          this.errorFactory.returnError(ex.errorCode, ex.errors[0].errorMessage);
        this.errorFactory.returnError(ex.errorCode, ex.errorCode);
      }
      var e = JSON.parse(ex.response);
      this.errorFactory.returnError(e.Errors[0].ErrorMessage, e.Errors[0].ErrorMessage);
      return new GetWizardRouterResponse();
    });
    return resultResp;
  }

  public async supervisorLauncherSendToOthers(sessionId: string, esignoSEApiAuth: EsignoSEApiAuth): Promise<SendToOtherAttendeesResponse>{
    var appGoWizardClient = new AppGoWizardSupervisorLauncherClient(await esignoSEApiAuth.getEseBaseUrl(), esignoSEApiAuth.fetchHandler);
    var resultResp = await appGoWizardClient.sendToOtherAttendees(sessionId).then(data => {
      return data;
    }).catch(ex => {
      if(ex instanceof ErrorResponse) {
        if(ex.errors != null)
          this.errorFactory.returnError(ex.errorCode, ex.errors[0].errorMessage);
        this.errorFactory.returnError(ex.errorCode, ex.errorCode);
      }
      var e = JSON.parse(ex.response);
      this.errorFactory.returnError(e.Errors[0].ErrorMessage, e.Errors[0].ErrorMessage);
      return new SendToOtherAttendeesResponse();
    });
    return resultResp;
  }

  public async getAllAttendees(sessionId: string, esignoSEApiAuth: EsignoSEApiAuth): Promise<GetSessionAttendeesInformationResponse>{
      var appGoWizardClient = new AppGoWizardSupervisorLauncherClient(await esignoSEApiAuth.getEseBaseUrl(), esignoSEApiAuth.fetchHandler);
      var resultResp = await appGoWizardClient.getSessionAttendeesInformation(sessionId).then(data => {
        return data;
      }).catch(ex => {
        if(ex instanceof ErrorResponse) {
          if(ex.errors != null)
            this.errorFactory.returnError(ex.errorCode, ex.errors[0].errorMessage);
          this.errorFactory.returnError(ex.errorCode, ex.errorCode);
        }
        var e = JSON.parse(ex.response);
        this.errorFactory.returnError(e.Errors[0].ErrorMessage, e.Errors[0].ErrorMessage);
        return new GetSessionAttendeesInformationResponse();
      });
      return resultResp;
  }

  public async getAllDocuments(sessionId: string, esignoSEApiAuth: EsignoSEApiAuth): Promise<GetAllSessionDocumentsResponse>{
    var appGoWizardClient = new AppGoWizardSupervisorLauncherClient(await esignoSEApiAuth.getEseBaseUrl(), esignoSEApiAuth.fetchHandler);
    var resultResp = await appGoWizardClient.getAllSessionDocuments(sessionId).then(data => {
      return data;
    }).catch(ex => {
      if(ex instanceof ErrorResponse) {
        if(ex.errors != null)
          this.errorFactory.returnError(ex.errorCode, ex.errors[0].errorMessage);
        this.errorFactory.returnError(ex.errorCode, ex.errorCode);
      }
      var e = JSON.parse(ex.response);
      this.errorFactory.returnError(e.errors[0].name, e.errors[0].name);
      return new GetAllSessionDocumentsResponse();
    });
    return resultResp;
  }

  public async getAllAuthMethods(sessionId: string, esignoSEApiAuth: EsignoSEApiAuth): Promise<GetClientSignMethodsResponse>{
    var appGoWizardClient = new AppGoWizardSupervisorLauncherClient(await esignoSEApiAuth.getEseBaseUrl(), esignoSEApiAuth.fetchHandler);
    var resultResp = await appGoWizardClient.getClientSignMethods(sessionId).then(data => {
      return data;
    }).catch(ex => {
      if(ex instanceof ErrorResponse) {
        if(ex.errors != null)
          this.errorFactory.returnError(ex.errorCode, ex.errors[0].errorMessage);
        this.errorFactory.returnError(ex.errorCode, ex.errorCode);
      }
      var e = JSON.parse(ex.response);
      this.errorFactory.returnError(e.Errors[0].ErrorMessage, e.Errors[0].ErrorMessage);
      return new GetClientSignMethodsResponse();
    });
    return resultResp;
  }

  public async getSignType(sessionId: string, esignoSEApiAuth: EsignoSEApiAuth): Promise<GetLauncherStateResponse>{
    var appGoWizardClient = new AppGoWizardSupervisorLauncherClient(await esignoSEApiAuth.getEseBaseUrl(), esignoSEApiAuth.fetchHandler);
    console.log(`appGoWizardClient.getSignState, sessionId=${sessionId}`);
    var resultResp = await appGoWizardClient.getSignState(sessionId).then(data => {
      return data;
    }).catch(ex => {
      console.error(`getSignType call returns error`, ex);
      if (ex instanceof ErrorResponse) {
        if(ex.errors != null)
          this.errorFactory.returnError(ex.errorCode, ex.errors[0].errorMessage);
          this.errorFactory.returnError(ex.errorCode, ex.errorCode);
      }
      return new GetLauncherStateResponse();
    });

    return resultResp;
  }

  public async signAll(sessionId: string, attendeeId: string, esignoSEApiAuth: EsignoSEApiAuth): Promise<SignAllResponse>{
    var appGoWizardClient = new AppGoWizardSupervisorLauncherClient(await esignoSEApiAuth.getEseBaseUrl(), esignoSEApiAuth.fetchHandler);

    const command = new SignAllDocumentsApiCommand();

    command.sessionId = sessionId;
    command.attendeeId = attendeeId;

    var resultResp = await appGoWizardClient.signAllDocuments(command).then(data => {
      return data;
    }).catch(ex => {
      if(ex instanceof ErrorResponse) {
        if(ex.errors != null)
          this.errorFactory.returnError(ex.errorCode, ex.errors[0].errorMessage);
        this.errorFactory.returnError(ex.errorCode, ex.errorCode);
      }
      var e = JSON.parse(ex.response);
      this.errorFactory.returnError(e.Errors[0].ErrorMessage, e.Errors[0].ErrorMessage);
      return new SignAllResponse();
    });
    return resultResp;
  }

  public async authMethod(sessionId: string, attendeeId: string): Promise<GetMethodsResponse>{
    var appGoWizardClient = new AppGoWizardAuthClient(await this.esignoSEApi.getEseBaseUrl(), this.esignoSEApi.fetchHandler);
    var resultResp = await appGoWizardClient.getAuthetnicationMethods(sessionId, attendeeId).then(data => {
      return data;
    }).catch(ex => {
      if(ex instanceof ErrorResponse) {
        if(ex.errors != null)
          this.errorFactory.returnError(ex.errorCode, ex.errors[0].errorMessage);
        this.errorFactory.returnError(ex.errorCode, ex.errorCode);
      }
      var e = JSON.parse(ex.response);
      this.errorFactory.returnError(e.Errors[0].ErrorMessage, e.Errors[0].ErrorMessage);
      return new GetMethodsResponse();
    });
    return resultResp;
  }

  public async getAttendeeInformation(sessionId: string, attendeeId: string): Promise<GetAttendeeInformationResponse>{
    var appGoWizardClient = new AppGoWizardClient(await this.esignoSEApi.getEseBaseUrl(), this.esignoSEApi.fetchHandler);
    var resultResp = await appGoWizardClient.getAttendee(sessionId, attendeeId).then(data => {
      return data;
    }).catch(ex => {
      if(ex instanceof ErrorResponse) {
        if(ex.errors != null)
          this.errorFactory.returnError(ex.errorCode, ex.errors[0].errorMessage);
        this.errorFactory.returnError(ex.errorCode, ex.errorCode);
      }
      var e = JSON.parse(ex.response);
      this.errorFactory.returnError(e.Errors[0].ErrorMessage, e.Errors[0].ErrorMessage);
      return new GetAttendeeInformationResponse();
    });
    return resultResp;
  }

  public async getSupervisorInformation(sessionId: string): Promise<GetSupervisorInformationResponse>{
    var appGoWizardClient = new AppGoWizardClient(await this.esignoSEApi.getEseBaseUrl(), this.esignoSEApi.fetchHandler);
    var resultResp = await appGoWizardClient.getSupervisor(sessionId).then(data => {
      return data;
    }).catch(ex => {
      this.errorFactory.DetectErrorResponse(ex);
      var e = JSON.parse(ex.response);
      this.errorFactory.returnError(e.Errors[0].ErrorMessage, e.Errors[0].ErrorMessage);
      return new GetSupervisorInformationResponse();
    });
    return resultResp;
  }

  public async getClientConfiguration(sessionId: string): Promise<GetGoClientConfigurationResponse>{
    var appGoWizardClient = new AppGoWizardClient(await this.esignoSEApi.getEseBaseUrl(), this.esignoSEApi.fetchHandler);
    var resultResp = await appGoWizardClient.getClientConfiguration(sessionId).then(data => {
      return data;
    }).catch(ex => {
      this.errorFactory.DetectErrorResponse(ex);
      var e = JSON.parse(ex.response);
      this.errorFactory.returnError(e.Errors[0].ErrorMessage, e.Errors[0].ErrorMessage);
      return new GetGoClientConfigurationResponse();
    });
    return resultResp;
  }

  public async getSignStateLocal(sessionId: string, esignoSEApiAuth: EsignoSEApiAuth): Promise<GetLauncherLocalStateResponse>{
    var appGoWizardClient = new AppGoWizardSupervisorLauncherClient(await esignoSEApiAuth.getEseBaseUrl(), esignoSEApiAuth.fetchHandler);
    console.log(`getSignStateLocal, [${sessionId}]`);
    var resultResp = await appGoWizardClient.getSignStateLocal(sessionId).then(data => {
      return data;
    }).catch(ex => {
      console.error(ex);

      if(ex instanceof ErrorResponse) {
        if(ex.errors != null)
          this.errorFactory.returnError(ex.errorCode, ex.errors[0].errorMessage);
        this.errorFactory.returnError(ex.errorCode, ex.errorCode);
      }
      var e = JSON.parse(ex.response);
      this.errorFactory.returnError(e.Errors[0].ErrorMessage, e.Errors[0].ErrorMessage);
      return new GetLauncherLocalStateResponse();
    });
    return resultResp;
  }
}

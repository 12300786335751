import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "mainDivLoading",
  style: {"background":"#FFFFFF","box-shadow":"0px 0px 20px rgba(0, 0, 0, 0.1)","border-radius":"8px","padding":"48px","max-width":"900px","text-align":"center","position":"sticky","z-index":"2","margin-bottom":"80px"}
}
const _hoisted_2 = {
  key: 0,
  class: "footerText"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_progress_circular, {
        size: 90,
        width: 8,
        color: this.$vuetify.theme.themes.light.colors.globalBackground,
        indeterminate: ""
      }, null, 8, ["color"])
    ]),
    (this.$vuetify.theme.themes.light.colors.showFooter == '1')
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t("footerInfo")), 1))
      : _createCommentVNode("", true)
  ], 64))
}
<script lang="ts">
import EsignoSEApiAuth from "@/assets/EsignoSEApiAuth";
import EsignoSEApiAuthUserClient from "@/assets/EsignoSEApiAuthUserClient";
import Navbar from "@/components/navbar.vue";
import Copyright from "@/components/new/copyright.vue";
import router from "@/router";
import {Options, Vue, Watch} from "vue-property-decorator";
import { useRoute } from "vue-router";
import {UserDto} from "@nubeo/ese5-client-ts";

@Options({
  components: { 
    Navbar,
    Copyright
  }
})
export default class AppLayout extends Vue {
  clientName: string = "";
  descClient: string = "";
  sessionId: string | null = null;
  accessToken: string | null = null;

  showUserPart: boolean = false;

  route = useRoute();

  esignoAuth: EsignoSEApiAuth
  esignoSEApiAuthUser: EsignoSEApiAuthUserClient;

  async mounted(){
    await this.RouteChanged();
  }



  async GetUserInformation(){
    if (this.accessToken == null){
      this.UserNotAuth();
      return;
    }

    const resultUserName = await this.esignoSEApiAuthUser.getCurrentUserName(this.sessionId!, this.accessToken!);
    if (resultUserName != null)
      this.clientName = resultUserName.name!;

    else{
      const refresh = await this.esignoAuth.refreshSession(sessionStorage.getItem("refreshToken"));
      if(refresh == null){
        this.SessionNotFound();
        return;
      }
      if(refresh?.refreshToken != null && refresh?.accessToken != null){
        sessionStorage.setItem("accessToken", refresh?.accessToken);
        sessionStorage.setItem("refreshToken", refresh?.refreshToken);
      }
      window.location.reload();
    }
  }

  SessionNotFound(){
    sessionStorage.removeItem("sessionId");
    sessionStorage.removeItem("showDetailUrl");
    sessionStorage.setItem("infoText", this.$t('dialogs.error.notFound.title'));
    sessionStorage.setItem("infoDescription", this.$t('dialogs.error.notFound.description'));

    router.push("/info-dialog/errorSignImage");
  }

  UserNotAuth(){
    sessionStorage.removeItem("sessionId");
    sessionStorage.removeItem("showDetailUrl");
    sessionStorage.setItem("infoText", this.$t('dialogs.error.userAuth.title'));
    sessionStorage.setItem("infoDescription", this.$t('dialogs.error.userAuth.description'));

    router.push("/info-dialog/errorSignImage")
  }

  LoadUserInfoByPath(path: string) : boolean{
    switch(path){
      case "/external-sign":
        return true;

      case "/upload":
        return true;

      case "/bankid-result":
        return true;

      case "/redirect":
        return true;

      case "/info":
        return true;

      case "/supervisor-launcher":
        return true;

      case "/choose-sign":
        return true;

      case "/qr-code":
        return true;

      case "/sms-sign":
        return true;
    }

    return false;
  }

  @Watch("route.path")
  async RouteChanged(){
    const needUser = this.LoadUserInfoByPath(router.currentRoute.value.path);

    if (!needUser){
      this.clientName = "";
      this.descClient = "";
      this.showUserPart = false;
      return;
    }

    this.showUserPart = true;

    this.esignoSEApiAuthUser = new EsignoSEApiAuthUserClient();
    this.sessionId = sessionStorage.getItem("sessionId");
    this.accessToken = sessionStorage.getItem("accessToken");
    if (this.sessionId == null || this.accessToken == null)
      return;

    this.esignoAuth = new EsignoSEApiAuth(this.accessToken);

    await this.GetUserInformation();
  }
}
</script>

<template>
  <navbar
    :name-of-client="clientName"
    :desc-of-client="descClient"
    :showPartInNavbarWithUserInfo="showUserPart"
    :showPartInNavbarWithUserInfoDownloadDialog="false"
  />
  <div class="app-layout">
    <router-view />  
    <copyright />
  </div>

</template>

<style scoped>
.app-layout {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: #EBEBEB;
    /* Background */
    background-image: var(--v-mainBackgroundUrl);
    background-repeat: repeat-x;
    background-position-x: center;
    background-position-y: top;
    background-size: auto;
    padding: 53px 8px;
    /* flex */
    display: flex;
    justify-content: center;
    align-items: start;
}


@media only screen and (max-width: 600px) {
  .app-layout{
    padding: 16px 8px;
  }
}

</style>

import { ref } from 'vue';
import axios from "axios";
import EsignoSEApiAuthLoginSession from "@/assets/EsignoSEApiAthLoginSession";
import Config from "@/assets/ts/Config";

let timeoutId: ReturnType<typeof setTimeout>;
export async function UserActivityTracking() {
  const config = await Config.get();

  const timeoutIdRef = ref<typeof timeoutId>();
  const timeInMinutes = config.periodOfInactivityRequiredToLogOutInMinutes;
  var accessToken = sessionStorage.getItem("accessToken");
  var eseAuth: EsignoSEApiAuthLoginSession;
  function resetTimeout() {
    clearTimeout(timeoutIdRef.value);
    timeoutIdRef.value = setTimeout(userNotActive, timeInMinutes * 60 * 1000);
  }

  async function userNotActive() {
    if(!window.location.pathname.includes("info-dialog")){
      console.log("User session closed due to inactivity");
      if(accessToken != null && accessToken != "") {
        await eseAuth.closeSession(accessToken);
        sessionStorage.removeItem("accessToken");
        sessionStorage.removeItem("attendeeId");
        sessionStorage.removeItem("refreshToken");
        sessionStorage.removeItem("sessionId");
        sessionStorage.setItem("userNotActive", "1");
      }
      window.location.reload();
    }
  }

  function handleUserActivity() {
    resetTimeout();
  }

  if(accessToken != null && accessToken != ""){
    eseAuth = new EsignoSEApiAuthLoginSession();

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    window.addEventListener("touchmove", handleUserActivity);

    resetTimeout();
  }
}

<template>
  <p class="footerText">{{ $t("footerInfo") }}</p>
</template>

<script lang="ts">
export default {
  name: "Copyright",
}
</script>

<style scoped>
</style>

import {
  AppGoWizardSessionClient,
  AuthLoginAttendeeSessionClient,
  CanOpenSession,
  ErrorResponse,
  UserSessionRefreshedResponse
} from "@nubeo/ese5-client-ts";
import axios from 'axios';
import ErrorFactory from "@/assets/ts/ErrorFactory";
import {RefreshAttendeeLoginSessionCommand} from "@nubeo/ese5-client-ts/src";
import {ref} from "vue";
import Config from "@/assets/ts/Config";

const logger = require('pino')();

export default class EsignoSEApiAuth {
  public fetchHandler: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> };
  errorFactory: ErrorFactory = new ErrorFactory();

  constructor(token: string) {
    this.fetchHandler = {
      async fetch(url: RequestInfo, init: RequestInit) {
        //const accessToken = await acquireAccessToken();
        //if (accessToken) init.headers['Authorization'] = `Bearer ${accessToken}`;

        //init.headers['ese-usertoken-v1'] = `SomeTokenValue`;
        if (init.headers != null) {
          init.headers = new Headers(init.headers);
          init.headers.set("ese-usertoken-v1", token);
        }
        return fetch(url, init);
      }
    }
  }

  public async getEseBaseUrl() : Promise<string> {
    const config = await Config.get();
    return config.apiUrl;
  }

  public async isSessionOpenable(): Promise<boolean>{
    let sessionId = sessionStorage.getItem("sessionId");
    if(! sessionId){
      //Nothing to be checked, if it is open - so return true to continue process
      return true;
    }

    var appGoWizardSessionClient = new AppGoWizardSessionClient(await this.getEseBaseUrl());
    try {
      let response = await appGoWizardSessionClient.canOpen(sessionId);
      return response.openable == CanOpenSession.OpenAllowed;
    } catch(ex) {
      //this.errorFactory.returnErrorFromException(ex);
      return false;
    }
  }

  public async refreshSession(refreshToken: string | null): Promise<UserSessionRefreshedResponse | null>{
    console.log(refreshToken);
    if(refreshToken != null){
      var importDefs = new EsignoSEApiAuth("");
      var userSessionClient = new AuthLoginAttendeeSessionClient(await importDefs.getEseBaseUrl());

      const command = new RefreshAttendeeLoginSessionCommand();

      command.refreshToken = refreshToken;

      var resultResp = await userSessionClient.refreshUserSession(command).then(data => {
        if(data.refreshToken != null && data.accessToken != null){
          sessionStorage.setItem("refreshToken", data.refreshToken);
          sessionStorage.setItem("accessToken", data.accessToken);
        }
        return data;
      }).catch(ex => {
        if(ex instanceof ErrorResponse) {
          if(ex.errorCode == "ESE_AUTH_002"  || ex.errorCode == "ESE_AUTH_003"){
            var sessionId = sessionStorage.getItem("sessionId");
            var attendeeId = sessionStorage.getItem("attendeeId");
            sessionStorage.removeItem("accessToken");
            sessionStorage.removeItem("refreshToken");
            window.location.href = "/open/" + sessionId + "/" + attendeeId;
          }
          if(ex.errors != null)
            this.errorFactory.returnError(ex.errorCode, ex.errors[0].errorMessage);
          this.errorFactory.returnError(ex.errorCode, ex.errorCode);
        }
        var e = JSON.parse(ex.response);
        this.errorFactory.returnError(e.Errors[0].ErrorMessage, e.Errors[0].ErrorMessage);
        return null;
      });
      return resultResp;
    }else
      return null;
  }
}

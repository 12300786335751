/* eslint-disable */
import { createI18n } from 'vue-i18n'
// @ts-ignore
import cs from "./translation/cs-CZ";
// @ts-ignore
import en from "./translation/en-US";

const i18n = createI18n({
  //locale: navigator.language.substr(0, 2),
  locale: "cs",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: { cs, en }
});

export default i18n;

// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import light from '../themes/generali';

// Vuetify
import { createVuetify } from "vuetify";

const opts = {
  theme: {
    themes: {
      light
    },
    options: { customProperties: true }
  },
}
console.warn = function () {};
export default createVuetify(opts);

import { createVuetify, ThemeDefinition } from 'vuetify'

const myCustomLightTheme: ThemeDefinition = {
    variables: {
        logo: '/img/generaliLogo.svg',
        logoUrl: 'url("/img/generaliLogo.svg")',
        GCP: '/img/generaliLogo.svg',
        GCD: '/img/generaliLogo.svg',
        PENZ: '/img/logo_penz.svg',
        mainBackground: '/img/bc-generali.svg',
        mainBackgroundUrl: 'url("/img/bc-generali.svg")',
        docSign: '/img/generali-signDoc.svg',
        docSignUrl: 'url("/img/generali-signDoc.svg")',
        docView: '/img/generali-doc.svg',
        docViewUrl: 'url("/img/generali-doc.svg")',
        docAttach: '/img/generaliAtt.svg',
        docAttachUrl: 'url("/img/generaliAtt.svg")',
        rightArrow: '/img/arrowRight.svg',
        rightArrowUrl: 'url("/img/arrowRight.svg")',
        supervisorLogo: '/img/generaliLion.svg',
        supervisorLogoUrl: 'url("/img/generaliLion.svg")',
        successIcon: '/img/done2.svg',
        successIconUrl: 'url("/img/done2.svg")',
        infoIcon: '/img/alert.svg',
        infoIconUrl: 'url("/img/alert.svg")',
        warningIcon: '/img/warning.svg',
        warningIconUrl: 'url("/img/warning.svg")',
        errorIcon: '/img/error2.svg',
        errorIconUrl: 'url("img/error2.svg")',
        textSecondary: '32, 37, 43, 0.6',
        borderColor: '0, 0, 0, 0.12',
        success: '85, 171, 103, 0.12',
        onSuccess: '0, 0, 0, 0.6',
        info: '67, 126, 181, 0.12',
        onInfo: '0, 0, 0, 0.6',
        warning: '255, 159, 0, 0.12',
        onWarning: '0, 0, 0, 0.6',
        error: '250, 234, 237, 1',
        onError: '0, 0, 0, 0.6',
        minusZoom: '/img/minusZoom.svg',
        plusZoom: '/img/plusZoom.svg'
    },
    colors: {
        navbar: '#C21B17',
        primary: '#E9573D',
        main: '#C21B17',
        'on-navbar': '#FFFFFF',

        'id': 'generali',
        'bcColor': '#C21B17',
        'textColor': 'white',
        'logo': '../img/generaliLogo.svg',
        'showCenterText': '1',
        'avatar': '/img/userAvatarDef.png',
        'showDescOfClient': '1',
        'showDocumentInfo': '1',
        'bcImage': '/img/bc-generali.svg',
        'showFooter': '1',
        'moreDetailedDialog': '0',
        'mainLogoNextToContactPeople': "../img/generaliLion.svg",
        'dotsImg': 'img/dotsGenerali.svg',
        'menuClickBc': '#faedec',
        'menuClickBorderBottom': '2px solid #c21b17',
        'globalBackground': '#C21B17',
        'titleColorUpload': '#E9573D',
        'showBackArrowUpload': '1',
        'showShadowOnDocsUploader': '1',
        'phoneSMS': '../img/phoneSMSGenerali.svg',
        'arrowLeft': '../img/arrowLeftGen.svg',
        'textImgBasedColor': '#C21B17',
        'docImg': '../img/generali-signDoc.svg',
        'attImg': '../img/generaliAtt.svg',
        'docSignImg': '../img/generali-doc.svg',
        'chooseSignMethodMainLabelColor': 'rgba(233, 87, 61, 1)',
        'checkFileBeforeInit': '1',
        'bcSigColor':'#FDCFCE',
        'textSigColor':'black',
        'docBcName':'rgb(217, 100, 97)',
        'showLanguageChoose': '0',
        'menuAboutApp':'#BF1B18'

    }
}

export default myCustomLightTheme;
